import { checkValueEqual } from '@gem/common';
import type { BorderStyle } from '@gem/element-setting-ui';
import type { ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';

export const useBorderWidth = (borderValues: ComputedRef<BorderStyle | undefined>, emit: any) => {
  const positionOptions = {
    top: {
      key: 'top',
      icon: 'border-position-top',
      iconPosition: 'first',
    },
    right: {
      key: 'right',
      icon: 'border-position-right',
      iconPosition: 'last',
    },
    bottom: {
      key: 'bottom',
      icon: 'border-position-bottom',
      iconPosition: 'first',
    },
    left: {
      key: 'left',
      icon: 'border-position-left',
      iconPosition: 'first',
    },
  };

  const mapKeyToPosition = (position: string) => {
    switch (position) {
      case 'top':
        return 0;
      case 'right':
        return 1;
      case 'bottom':
        return 2;
      case 'left':
        return 3;
      default:
        return -1;
    }
  };

  const keyActive = ref<string>('');
  const positionWidths = ref(borderValues.value?.width?.split(' '));
  const isActiveLink = ref<boolean>(checkValueEqual(positionWidths.value || []));

  const isLink = computed(() => isActiveLink.value ?? checkValueEqual(positionWidths.value || []));

  const getPositionWidth = (position: string) => {
    return positionWidths?.value?.[mapKeyToPosition(position)];
  };

  const getCurrentWidth = (value: string) => {
    if (!keyActive.value) return;
    const width = value;
    if (isLink.value) {
      positionWidths.value = [width, width, width, width];
      return positionWidths.value?.join(' ');
    }
    const index = mapKeyToPosition(keyActive.value);
    if (index !== -1 && positionWidths.value?.[index]) {
      positionWidths.value[index] = width;
    }
    return positionWidths.value?.join(' ');
  };

  const handleOnChangeWidth = (key: string, value: string) => {
    keyActive.value = key;
    handleEmit('controlOnChange', getCurrentWidth(value));
  };

  const handleInputBlur = (key: string, value: string) => {
    keyActive.value = key;
    const newVal = getCurrentWidth(value);
    if (newVal !== borderValues.value?.width) handleEmit('controlChange', newVal);
  };

  const handleEmit = (type: 'controlOnChange' | 'controlChange', data?: string) => {
    if (type === 'controlChange') emit('controlChange', data);
    else emit('controlOnChange', data);
  };

  const handleClickLinkAction = () => {
    isActiveLink.value = !isActiveLink.value;
    if (isActiveLink.value && !checkValueEqual(positionWidths.value || [])) {
      const width = positionWidths.value?.[0] || '0px';
      positionWidths.value = [width, width, width, width];
      handleEmit('controlChange', positionWidths.value?.join(' '));
    }
  };

  watch(
    () => borderValues.value,
    (newVal) => {
      if (newVal) {
        positionWidths.value = newVal.width?.split(' ');
      }
    },
  );

  watch(isActiveLink, () => {
    emit('controlLinked', isActiveLink.value);
  });

  return {
    positionOptions,
    isLink,
    getPositionWidth,
    handleOnChangeWidth,
    handleInputBlur,
    handleClickLinkAction,
  };
};

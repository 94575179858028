import ElementSettings from './sidebar-setting/ElementSettings.vue';
import SettingTitle from './sidebar-setting/SettingTitle.vue';
import ComboSetting from './sidebar-setting/ComboSetting.vue';
import SettingLayout from './sidebar-setting/layout/SettingLayout.vue';
import InputComboLayout from './sidebar-setting/layout/InputComboLayout.vue';
import PresetSettingLayout from './sidebar-setting/layout/PresetSettingLayout.vue';
import Control from './sidebar-setting/Control.vue';
import Select from './components/base/Select.vue';

export {
  ElementSettings,
  SettingTitle,
  ComboSetting,
  SettingLayout,
  InputComboLayout,
  Control,
  PresetSettingLayout,
  Select,
};

export * from './components/index';

export * from './sidebar-setting/types';

export * from './helper';

export * from './sidebar-setting/hooks/useControlChange';

export const CONTROL_TYPE_WITH_POPOVER_NUMBER = {
  1: [
    'color-picker-v2',
    'corner-v2',
    'padding-v2',
    'background-image',
    'background-video',
    'background-media',
    'radius-preset',
    'image',
  ],
  2: ['border', 'border-v2'],
};

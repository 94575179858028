<script setup lang="ts">
import type { SettingUIMoreSetting } from '@gem/element-setting-ui';
import { GButton, GPopover } from '@gem/uikit-v2';
import { computed, ref, useSlots } from 'vue';
import SettingTitle from './SettingTitle.vue';
import { ID } from '@gem/common';

type MoreSettingsProps = {
  data?: SettingUIMoreSetting;
};

const emit = defineEmits<{
  (event: 'open'): void;
  (event: 'close'): void;
}>();

const props = defineProps<MoreSettingsProps>();

const slots = useSlots();

const actionType = computed(() => props.data?.type || 'collapse');
const moreSettingID = computed(() => `gp-more-setting-${ID()}`);

const isOpen = ref(false);
const collapsed = ref(true);
const handleCollapsed = () => {
  collapsed.value = !collapsed.value;
};

const handleOpenMoreSettings = () => {
  isOpen.value = true;
  emit('open');
};

const handleClosePopover = () => {
  isOpen.value = false;
  emit('close');
};
</script>

<template>
  <template v-if="slots.default">
    <template v-if="actionType === 'collapse'">
      <div class="flex flex-col gap-16">
        <slot v-if="!collapsed"></slot>
        <GButton
          type="secondaryGhost"
          size="medium"
          button-width="full"
          icon-view-box="0 0 16 16"
          :icon-after="collapsed ? 'polaris-chevron-down' : 'polaris-chevron-up'"
          @click="handleCollapsed()">
          {{ collapsed ? 'Show more' : 'Show less' }}
        </GButton>
      </div>
    </template>
    <template v-else>
      <GPopover
        v-if="data"
        :no-padding="true"
        :placement-offset="20"
        closeable
        cls="bg-dark-500"
        :button-class="actionType === 'button' ? '!w-full' : ''"
        :wrap-content-class="actionType === 'button' ? '!w-full' : ''"
        wrapper-class="transform -translate-y-[12px]"
        :ignore-outside-class="[moreSettingID]"
        :enable-flip="false"
        placement="right-start"
        @close="handleClosePopover"
        @open="handleOpenMoreSettings">
        <div class="w-full" :class="moreSettingID">
          <GButton
            v-if="actionType === 'button'"
            :active="isOpen"
            type="secondary"
            size="medium"
            icon-after="polaris-chevron-right"
            icon-view-box="0 0 16 16"
            button-width="full">
            {{ data.labelAction?.en }}
          </GButton>
          <GButton
            v-if="actionType === 'icon'"
            :active="isOpen"
            :only-icon="'polaris-menu-horizontal'"
            size="small"
            type="ghost" />
        </div>
        <template #content="{ close }">
          <div class="w-[280px]">
            <div class="border-dark-300 flex items-center justify-between border-b py-10 pl-16 pr-10">
              <SettingTitle :label="data.label" />
              <GButton only-icon="polaris-x" type="ghost" size="semi-medium" @click="close" />
            </div>

            <div class="flex max-h-[calc(100vh-200px)] flex-col gap-16 py-16">
              <perfect-scrollbar>
                <div class="px-16">
                  <slot></slot>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </template>
      </GPopover>
    </template>
  </template>
</template>

import { computed, ref, watch } from 'vue';
import type { ColorPickerProps, TAB_TYPE } from '../types';
import type { ColorProps, CustomColorType } from '@gem/common';
import { getColorFormGlobalStyle, isColor } from '@gem/common';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';
import { checkIsColorGradient, TRANSPARENT_COLOR } from '../helpers';
import useMyColor from './useMyColor';
import useSuggestedColor from './useSuggestedColor';

export const useColorPicker = (colorPickerProps: ColorPickerProps, emit: any) => {
  const settingSideBarStore = useSettingSideBarStore();
  const colorMode = ref<TAB_TYPE>(checkIsColorGradient(colorPickerProps.value || '') ? 'gradient' : 'solid');
  const solidColorCache = ref(colorMode.value === 'solid' ? colorPickerProps.value : undefined);
  const gradientColorCache = ref(colorMode.value === 'gradient' ? colorPickerProps.value : undefined);

  const openColorPicker = ref(false);
  const shouldReRenderDueToInputChange = ref(false);
  const clearValue = 'transparent';

  const defaultValue = computed(() => colorPickerProps.compoDefaultValue || '#212121');
  const value = computed(() => colorPickerProps.value);
  const displayValue = computed(() => {
    if (colorPickerProps.value === clearValue) return undefined;
    return getColorFormGlobalStyle(colorPickerProps.value, settingSideBarStore.globalStyles);
  });
  const showTransparentColor = computed(() => !colorPickerProps.value || colorPickerProps.value === TRANSPARENT_COLOR);
  const currentGlobalStyleColor = computed(() => settingSideBarStore.globalStyles?.color || {});
  const globalColors = computed(() => convertObjectToList(currentGlobalStyleColor.value));
  const composeColors = computed(() => {
    if (!colorPickerProps.value || colorPickerProps.value === 'transparent') return '';
    if (isColor(colorPickerProps.value)) return colorPickerProps.value;
    return globalColors.value?.find((col) => col.colorType === colorPickerProps.value)?.color as string;
  });
  const options = useSuggestedColor(globalColors, value);

  const { myColors, SUGGESTED_COLOR_FOR_YOU: suggestColors, getMyColorChange } = useMyColor(globalColors);

  const handleSetDefaultValue = (forceReset?: boolean) => {
    if (!displayValue.value || forceReset) {
      colorMode.value = checkIsColorGradient(defaultValue.value) ? 'gradient' : 'solid';
      emit('controlChange', defaultValue.value);
    }
  };

  const handleClearColor = () => {
    emit('controlChange', clearValue);
  };

  const onChangeSolidColor = (color: string) => {
    onChangeColor(color);
    shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
  };

  const changeSolidColor = (color: string) => {
    changeColor(color);
    shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
  };

  const onChangeColor = (color: string) => {
    const newColor = color ? color : TRANSPARENT_COLOR;
    emit('controlOnChange', colorPickerProps.id, newColor);
  };

  const changeColor = (color: string) => {
    const newColor = color ? color : TRANSPARENT_COLOR;
    if (!myColors.value.includes(newColor)) {
      emit('saveMyColors', getMyColorChange(newColor));
    }
    emit('controlChange', colorPickerProps.id, newColor);
  };

  const onToggleColorPicker = () => {
    openColorPicker.value = !openColorPicker.value;
  };

  const convertObjectToList = (obj: Partial<Record<CustomColorType, string>>): ColorProps[] => {
    const colors: ColorProps[] = [];
    Object.assign(
      colors,
      Object.entries(obj).map(([key, value]) => {
        if ((key as keyof CustomColorType) && typeof value !== 'object') {
          return {
            colorType: key as CustomColorType,
            color: value,
          };
        }
        return {};
      }),
    );
    return colors.filter((col) => !!col.colorType).concat({ colorType: 'transparent', color: 'transparent' });
  };

  watch(colorPickerProps, () => {
    colorMode.value = checkIsColorGradient(colorPickerProps.value || '') ? 'gradient' : 'solid';
  });
  return {
    displayValue,
    colorValue: displayValue,
    openColorPicker,
    composeColors,
    showTransparentColor,
    shouldReRenderDueToInputChange,
    myColors,
    suggestColors,
    options,
    colorMode,
    solidColorCache,
    gradientColorCache,
    handleSetDefaultValue,
    handleClearColor,
    onToggleColorPicker,
    onChangeSolidColor,
    onChangeColor,
    changeColor,
    changeSolidColor,
  };
};

<script setup lang="ts">
import type { OptionItem } from '@gem/element-setting-ui';
import { Segment } from '@gem/element-setting-ui';
type PropsType = {
  id?: string;
  value?: boolean;
};
defineProps<PropsType>();
const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: boolean): void;
}>();

const handleControlChange = (controlId: string, value?: OptionItem['value']) => {
  emit('controlChange', controlId, value as boolean);
};
</script>

<template>
  <Segment
    :id="id || ''"
    :value="value"
    :options="[
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ]"
    @control-change="handleControlChange" />
</template>

<script setup lang="ts">
import type { CornerSettingProps } from './types';
import { InputComboLayout, SettingLayout } from '@gem/element-setting-ui';
import { useCornerSettings } from './hooks/useCornerSettings';
import { InputUnitWithDropdown } from '@gem/element-setting-ui';
import { GButton } from '@gem/uikit-v2';

const props = defineProps<CornerSettingProps>();

const emit = defineEmits<{
  (e: 'controlChange', value?: CornerSettingProps['value']): void;
  (e: 'controlOnChange', value?: CornerSettingProps['value']): void;
}>();

const {
  cornerValues,
  isLinked,
  isFocus,
  displayComboValue,
  displayComboIcon,
  options,
  borderRadiusInputs,
  handleClickPopover,
  handleClearCorner,
  toggleLinked,
  changeInput,
  onChangeInput,
} = useCornerSettings(props, emit);
</script>
<template>
  <div>
    <InputComboLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :label="{ en: 'Corner' }"
      :show-label="false"
      :is-full-width="true"
      :content-display="displayComboValue"
      :combo-icon="displayComboIcon"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleClickPopover"
      @clear="handleClearCorner">
      <SettingLayout :label="{ en: 'Radius' }" layout="vertical">
        <template #control>
          <div class="item flex items-start gap-8">
            <div class="w-full items-center gap-8" :class="{ 'grid grid-cols-2': !isLinked }">
              <template v-for="(item, index) in borderRadiusInputs" :key="index">
                <InputUnitWithDropdown
                  :id="item"
                  :align="'center'"
                  :value="cornerValues?.[item]"
                  :display-options="isLinked ? options : []"
                  :active="isFocus && item === 'btlr'"
                  :units="['px', '%']"
                  :dropdown-width="'211px'"
                  @control-change="(value: string) => changeInput(item, value)"
                  @control-on-change="(value: string) => onChangeInput(item, value)"
              /></template>
            </div>
            <GButton
              type="ghost"
              size="semi-medium"
              only-icon="gp-corner-single"
              :icon-size="20"
              icon-view-box="0 0 20 20"
              :active="!isLinked"
              @click="toggleLinked" />
          </div>
        </template>
      </SettingLayout>
    </InputComboLayout>
  </div>
</template>

import type { ControlChangeValueType } from '@gem/element-setting-ui';
import { strictInject } from '@gem/element-setting-ui';
import { ELEMENT_SETTINGS_PROVIDE_KEY } from '../../consts/provideKeys';
import type { ComputedRef } from 'vue';
import type { ScreenType } from '@gem/common';

type ControlChangeConfig = {
  screen?: ScreenType;
  id?: string;
};
export const useControlChange = (baseProps: ComputedRef<ControlChangeValueType>) => {
  const { controlChange, controlOnChange } = strictInject(ELEMENT_SETTINGS_PROVIDE_KEY);

  const change = (newValue: any, config?: ControlChangeConfig) => {
    const newData = { ...baseProps.value, val: newValue, ...(config || {}) };
    controlChange(newData);
  };

  const onChange = (newValue: any, config?: ControlChangeConfig) => {
    const newData = { ...baseProps.value, val: newValue, ...(config || {}) };
    controlOnChange(newData);
  };

  return {
    change,
    onChange,
  };
};

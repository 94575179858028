import { debounce, parseUnit } from '@gem/common';
import { computed, ref, watch, nextTick } from 'vue';
import type { ControlInputUnitDropdownTypes } from '../type';
import { useInputValidateValue } from './useInputValidateValue';

export const useInputUnitWithDropdown = (
  props: ControlInputUnitDropdownTypes & { globalStyleContainerWidth?: any },
  emit: any,
) => {
  const { modifyValueByMinMax, handleNegativeValue } = useInputValidateValue(props);
  const inputEl = ref<any>();
  const dropdownRef = ref();
  const isFocus = ref(false);
  const isShowDropDownButton = ref(false);
  const isHoverButton = ref(false);
  const defaultUnit = computed(() => props.defaultUnit ?? 'px');
  const val = computed(() => props.value);
  const previousInputValue = ref<string | undefined>(props.value ? props.value : '');
  const globalStyleContainerWidth = computed<string>(() => props?.globalStyleContainerWidth || '');
  const isActive = computed(() => props.active);

  const isEnableDropdown = computed(() => props.displayOptions?.length && isShowDropDownButton.value && !props.disable);

  const initValue = (value?: string) => {
    if (value === undefined) return undefined;
    if (value.toLocaleLowerCase() === 'auto') return 'Auto';
    let displayValue = value;
    if (value === 'default' && globalStyleContainerWidth.value) displayValue = globalStyleContainerWidth.value;
    const [textValue, unit] = parseUnit(displayValue);
    if (props.units?.includes(unit))
      return unit === defaultUnit.value && !props.showDefaultUnit ? textValue : `${textValue}${unit}`;
    return textValue;
  };

  const inputValue = computed(() => {
    return initValue(val.value);
  });

  const handleEmit = (type: 'change' | 'onChange', value?: string) => {
    if (value === previousInputValue.value) return;
    if (type === 'change') {
      previousInputValue.value = value;
      emit('controlChange', value);
    } else {
      emit('controlOnChange', value);
    }
  };

  const handleSelect = (value: string) => {
    if (!val.value || value === val.value) return;
    isShowDropDownButton.value = false;
    handleEmit('change', value);
  };

  const processInputValue = (value: string): string | undefined => {
    if (value === undefined) {
      return undefined;
    }
    if (value.toLocaleLowerCase() === 'auto') return 'Auto';

    let validValue = modifyValueByMinMax(value);
    if (!props.enableNegative) validValue = handleNegativeValue(validValue);
    const [textValue, unit] = parseUnit(validValue || value);
    let newValue = textValue;
    if (isNaN(Number(newValue))) newValue = '0';

    if (props.units?.includes(unit)) return `${newValue}${unit}`;

    return `${newValue}${defaultUnit.value}`;
  };

  const handleInputChange = (value: string) => {
    const val = processInputValue(value);
    inputEl.value && inputEl.value?.updateValue(initValue(val));
    handleEmit('change', val);
  };

  const handleInputOnChange = (value: string) => {
    const val = processInputValue(value);
    handleEmit('onChange', val);
  };

  const handleUpDownValue = (e: Event, stepVal: number) => {
    e.preventDefault();
    debounce(() => {
      const [textValue, unit] = parseUnit((e.target as HTMLInputElement).value);
      const newValue = Number(textValue) ? Number(textValue) + Number(stepVal) : Number(stepVal);
      const value = `${newValue}${unit ? unit : defaultUnit.value}`;
      inputEl.value && inputEl.value?.updateValue(initValue(value));
      (e.target as HTMLInputElement).value = value;
      handleEmit('onChange', value);
    }, 10);
  };

  const onMouseLeave = () => {
    !dropdownRef.value?.open && (isShowDropDownButton.value = false);
  };

  const onMouseover = () => (isShowDropDownButton.value = true);

  const onMouseOverButton = () => {
    if (isFocus.value) isHoverButton.value = true;
  };

  const onMouseLeaveButton = () => {
    if (isFocus.value) isHoverButton.value = false;
  };

  watch(
    () => props.active,
    () => {
      nextTick(() => {
        if (props.active) inputEl.value?.focus();
      });
    },
  );

  return {
    val,
    isFocus,
    inputEl,
    isActive,
    inputValue,
    dropdownRef,
    isHoverButton,
    isEnableDropdown,
    isShowDropDownButton,
    onMouseover,
    onMouseLeave,
    handleSelect,
    handleInputChange,
    handleInputOnChange,
    handleEmit,
    handleUpDownValue,
    onMouseOverButton,
    onMouseLeaveButton,
  };
};

import { computed, ref, onMounted, watch, onUnmounted } from 'vue';
import {
  generateNewGlobalStyleColor,
  makeGradientColorFormColor,
  toGradientObject,
  TRANSPARENT_COLOR,
} from '../helpers';
import useGradientColor from '../hooks/useGradientColor';
import type { ColorPickerProps, GradientColor } from '../types';
import { MAX_COLOR_SIZE, START_CHANGE_INDEX_OF_GRADIENT_TAB } from './useMyColor';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';

export function useGradientColorPicker(props: ColorPickerProps, emits: any) {
  const settingSideBarStore = useSettingSideBarStore();

  const getGradientColor = computed(() => {
    const gradientColor = makeGradientColorFormColor(props.value);
    return makeGradientColorFormColor(gradientColor);
  });

  const gradientColorObject = ref<GradientColor | undefined>(toGradientObject(getGradientColor.value));
  const shouldReRenderDueToInputChange = ref(false);
  const currentGlobalStyleColor = computed(() => settingSideBarStore.globalStyles?.color || {});

  const recentGradientColors = computed(() =>
    currentGlobalStyleColor.value && currentGlobalStyleColor.value['recent-gradient-colors']
      ? currentGlobalStyleColor.value['recent-gradient-colors']
      : [],
  );

  const getMyColorChange = (newColor: string | undefined) => {
    if (!newColor) return;

    const changeIndex = currentGlobalStyleColor?.value['gradient-index-change'] || 5;
    const changeInfo = {
      colorKeys: ['recent-gradient-colors'],
      changeIndexKey: 'gradient-index-change',
      initStartIndex: START_CHANGE_INDEX_OF_GRADIENT_TAB,
      changeIndex,
    };
    return generateNewGlobalStyleColor(newColor, recentGradientColors.value, MAX_COLOR_SIZE, changeInfo);
  };

  const changeColor = (color: string, type?: 'change' | 'onChange') => {
    const newColor = (color || TRANSPARENT_COLOR).toLowerCase();
    if (type === 'onChange') emits('controlOnChange', props.id, newColor);
    else {
      emits('controlChange', props.id, newColor);
    }
  };

  const {
    selectedPointID,
    selectedPoint,
    selectedPointPosition,
    addPoint,
    updateAngle,
    updateColor,
    updatePosition,
    removePoint,
    selectPoint,
    setSelectedPointID,
    pickRecentColor,
  } = useGradientColor(gradientColorObject, changeColor);

  const showTransparentColor = computed(() => !props.value || props.value === TRANSPARENT_COLOR);

  const handleInputChange = (value: string) => {
    updateColor(value);
    shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
  };

  const handleInputOnChange = (value: string) => {
    updateColor(value, 'onChange');
    shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
  };

  onMounted(() => {
    if (!selectedPointID.value && gradientColorObject.value) {
      setSelectedPointID([...gradientColorObject.value.points.keys()][0]);
    }
  });

  onUnmounted(() => {
    if (!recentGradientColors.value.includes(getGradientColor.value)) {
      emits('saveMyColors', getMyColorChange(getGradientColor.value));
    }
  });

  const recoverSelectedPoint = (gradientColorObject: GradientColor | undefined) => {
    if (!gradientColorObject) return;
    selectedPointID.value = [...gradientColorObject!.points.keys()][0];
    if (selectedPointPosition.value !== undefined) {
      gradientColorObject.points.forEach((point, pointId) => {
        if (point.position === selectedPointPosition.value) {
          selectedPointID.value = pointId;
        }
      });
    }
  };

  watch(
    () => props.value,
    () => {
      gradientColorObject.value = toGradientObject(getGradientColor.value);
      recoverSelectedPoint(gradientColorObject.value);
    },
  );

  return {
    gradientColorObject,
    selectedPointID,
    selectedPoint,
    showTransparentColor,
    shouldReRenderDueToInputChange,
    recentGradientColors,
    handleInputChange,
    handleInputOnChange,
    updateColor,
    removePoint,
    pickRecentColor,
    addPoint,
    updateAngle,
    updatePosition,
    selectPoint,
  };
}

<script setup lang="ts">
import { InputUnitWithDropdown, type BorderStyle } from '@gem/element-setting-ui';
import { useBorderWidth } from './hooks/useBorderWidth';
import { computed } from 'vue';
import { GButton } from '@gem/uikit-v2';

type Props = {
  id: string;
  value?: BorderStyle;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
  (e: 'controlLinked', value?: boolean): void;
}>();

const borderValues = computed(() => props.value);
const { positionOptions, isLink, getPositionWidth, handleOnChangeWidth, handleInputBlur, handleClickLinkAction } =
  useBorderWidth(borderValues, emit);
</script>

<template>
  <div class="flex w-full gap-8">
    <div class="flex w-full flex-col gap-y-8">
      <template v-for="(item, key) in positionOptions">
        <InputUnitWithDropdown
          v-if="!(isLink && key !== 'top')"
          :id="id"
          :key="item"
          :align="'center'"
          :value="getPositionWidth(key)"
          :prefix-icon="isLink ? undefined : `gp-border-${key}`"
          :units="['px']"
          :min="0"
          :dropdown-width="'211px'"
          @control-change="(value: string) => handleInputBlur(key, value)"
          @control-on-change="(value: string) => handleOnChangeWidth(key, value)" />
      </template>
    </div>
    <div class="flex-1">
      <GButton
        type="ghost"
        :active="!isLink"
        size="medium"
        only-icon="gp-border-separated"
        @click="handleClickLinkAction" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { SettingUIToggleSettings } from '@gem/element-setting-ui';
import { GButton, GDropdownMenu, GDropdownMenuItemProps, GPopover } from '@gem/uikit-v2';
import { useGroupToggleSettingUpdate } from './hooks/useGroupToggleSettingUpdate';

type ToggleSettingProps = {
  data?: SettingUIToggleSettings[];
};

const props = defineProps<ToggleSettingProps>();

const emit = defineEmits<(e: 'onSelect', value: string[]) => void>();
const isOpen = ref(false);

const { toggleSettingTabs, isCanPlus, toggleSettingsTabSelected, toggleClearSettings, handleDisplaySettings } =
  useGroupToggleSettingUpdate(props, emit);
</script>

<template>
  <GPopover
    v-if="toggleSettingTabs?.length"
    :no-padding="true"
    trigger="click"
    :closeable="true"
    :has-arrow="false"
    :placement-offset="4"
    placement="bottom-end"
    @open="isOpen = true"
    @close="isOpen = false">
    <GButton
      :active="isOpen"
      type="ghost"
      size="medium"
      :data-test-item-length="toggleSettingTabs?.length"
      :only-icon="isCanPlus ? 'polaris-plus' : 'polaris-minus'"
      @click="toggleClearSettings" />
    <template #content>
      <GDropdownMenu
        :items="toggleSettingTabs as GDropdownMenuItemProps[]"
        :is-multi-select="true"
        :selected="toggleSettingsTabSelected"
        @on-select="handleDisplaySettings"
    /></template>
  </GPopover>
</template>

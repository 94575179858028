<script setup lang="ts">
import { GInput, GDropdownMenu, GPopover, GButton } from '@gem/uikit-v2';
import { useInputUnitWithDropdown } from './hooks/useInputUnitWithDropdown';
import type { ControlInputUnitDropdownTypes } from './type';

const props = defineProps<ControlInputUnitDropdownTypes & { globalStyleContainerWidth?: any }>();

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
}>();

const {
  val,
  isFocus,
  inputEl,
  isActive,
  inputValue,
  dropdownRef,
  isHoverButton,
  isEnableDropdown,
  isShowDropDownButton,
  onMouseover,
  onMouseLeave,
  handleSelect,
  handleInputChange,
  handleInputOnChange,
  handleUpDownValue,
  onMouseOverButton,
  onMouseLeaveButton,
} = useInputUnitWithDropdown(props, emit);
</script>

<template>
  <div
    class="bg-dark-400 group relative rounded-xl border border-transparent"
    :class="{ 'border-primary-300': isFocus || isHoverButton }"
    @mouseover="onMouseover"
    @mouseleave="onMouseLeave">
    <GInput
      v-bind="props"
      ref="inputEl"
      :align="align ?? 'left'"
      :value="inputValue"
      :is-unit="true"
      :disable="disable"
      :active="isActive"
      :no-background="true"
      no-border="all"
      @focus="
        () => {
          isFocus = true;
          isShowDropDownButton = true;
        }
      "
      @focusout="() => (isFocus = false)"
      @keydown.up="(e: Event) => handleUpDownValue(e, 1)"
      @keydown.down="(e: Event) => handleUpDownValue(e, -1)"
      @click-out-side="handleInputChange"
      @on-change="handleInputOnChange" />
    <div v-if="isEnableDropdown" class="absolute top-0 right-0 cursor-pointer">
      <GPopover
        ref="dropdownRef"
        :has-arrow="false"
        :closeable="true"
        :overlay="false"
        :offset-top="-2"
        :z-index="9999"
        overlay-container="#root-modal"
        placement="bottom-end"
        cls="!p-0"
        @open="() => (isFocus = true)"
        @close="
          () => {
            isFocus = false;
            isShowDropDownButton = false;
          }
        ">
        <template #default="{ open }">
          <GButton
            type="dropdownInput"
            :size="dropdownButtonSize ? dropdownButtonSize : '36-28'"
            only-icon="polaris-caret-down"
            :active="open"
            no-rounded="left"
            @mouseover="onMouseOverButton"
            @mouseleave="onMouseLeaveButton" />
        </template>
        <template #content="{ close }">
          <GDropdownMenu
            :width="dropdownWidth ?? '200px'"
            :items="displayOptions"
            :show-checked-icon="true"
            :is-multi-select="false"
            :selected="[val]"
            :more-action="dropdownMoreAction"
            @on-select="
              (value: string[]) => {
                close();
                handleSelect(value[0]);
              }
            " />
        </template>
      </GPopover>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  InputComboLayout,
  CONTROL_TYPE_WITH_POPOVER_NUMBER,
  type SettingUIControl,
  useControlChange,
} from '@gem/element-setting-ui';
import ControlSettings from './ControlSettings.vue';
import { computed, ref } from 'vue';
import useControlData from '../hooks/useControlData';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
import { getColorFormGlobalStyle } from '@gem/common';

type ComboSettingProps = {
  data: SettingUIControl;
};

const props = defineProps<ComboSettingProps>();

const settingSideBarStore = useSettingSideBarStore();

const comboIcon = ref(props.data.iconName);
const linkWithSettingValue = computed(() => getContentByControlID(props.data.getValueFromSettingID));

const hasSettingID = computed(() => props.data.setting?.id);
const controlIDGetContent = computed(() => props.data.getValueFromSettingID);
const defaultValueWhenClear = computed(() => props.data.defaultValueWhenClear);
const fixedValue = computed(() => props.data.fixedValue);
const isFullWidth = computed(() =>
  props.data?.options?.fullWidth === undefined ? true : props.data?.options?.fullWidth,
);

const controlData = computed(() => props.data);
const { controlProps } = useControlData({
  controlData,
});
const isEnable = computed(() => controlProps?.value?.value);

const contentDisplay = computed(() => {
  if (hasSettingID.value) return isEnable.value ? fixedValue.value : '';
  if (props.data.comboType === 'color') {
    return getColorFormGlobalStyle(linkWithSettingValue.value, settingSideBarStore.globalStyles);
  }
  return linkWithSettingValue.value ?? fixedValue.value;
});

function getContentByControlID(controlID?: string) {
  const control = props.data.controls?.find((item) => item.mapTo?.control?.id === controlID)?.mapTo?.control;
  return control?.default;
}
const controlChangeData = computed(() => controlProps.value.controlChangeData);
const { change } = useControlChange(controlChangeData);

const handleClear = () => {
  if (props.data.setting?.id) {
    change(false);
  } else {
    if (controlIDGetContent.value) {
      change(defaultValueWhenClear.value, { id: controlIDGetContent.value });
    }
  }
};

const handleEnableCombo = () => {
  if (props.data.setting?.id && !isEnable.value) {
    change(true);
  }
};

const totalPopover = computed(() => {
  let total = 0;
  function loopControl(controls: SettingUIControl[]) {
    if (controls?.length) {
      total++;
      controls.forEach((item) => {
        if (item.type === 'combo') total++;
        if (CONTROL_TYPE_WITH_POPOVER_NUMBER[1].includes(item.mapTo?.control.type || '')) total += 1;
        if (CONTROL_TYPE_WITH_POPOVER_NUMBER[2].includes(item.mapTo?.control.type || '')) total += 2;
        loopControl(item.controls || []);
      });
    }
  }
  if (props.data.controls?.length) {
    loopControl(props.data.controls);
  }
  return total;
});
</script>

<template>
  <div data-test="combo-setting">
    <InputComboLayout
      :id="controlProps?.id"
      :label="data?.label"
      :is-full-width="isFullWidth"
      :help="data.help"
      :level="data.level || 1"
      :total-popover="totalPopover"
      :placeholder="data.placeholder"
      :popover-label="data.popoverLabel"
      :combo-icon="contentDisplay ? comboIcon : undefined"
      :combo-color="data.comboType === 'color' ? contentDisplay : undefined"
      :combo-image="data.comboType === 'image' ? contentDisplay : undefined"
      :content-display="contentDisplay?.replace('#', '')"
      :is-hide-clear="data?.isHideClear"
      :combo-i-d="data?.comboID || ''"
      :combo-parent-i-d="data?.comboParentID || ''"
      :combo-root-parent-i-d="data?.comboRootParentID || ''"
      data-test="InputComboLayout"
      @click="handleEnableCombo"
      @clear="handleClear">
      <ControlSettings v-if="data.controls?.length" :controls="data.controls" :total-popover="totalPopover" />
    </InputComboLayout>
  </div>
</template>

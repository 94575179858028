import type { ControlInputTextTypes } from '@gem/element-setting-ui';
import { ref } from 'vue';

export const useInputText = (props: ControlInputTextTypes, emit: any) => {
  const previousValue = ref(props.value ? props.value : '');

  const handleEmit = (type: 'change' | 'onChange', value?: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue === previousValue.value) return;

    if (type === 'onChange') {
      emit('controlOnChange', trimmedValue);
    } else if (type === 'change') {
      previousValue.value = value;
      emit('controlChange', trimmedValue);
    }
  };

  const handleControlChange = (value: string) => {
    handleEmit('change', value);
  };

  const handleControlOnChange = (value: string) => {
    handleEmit('onChange', value);
  };

  return { handleControlChange, handleControlOnChange };
};

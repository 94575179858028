import { computed } from 'vue';
import type { BackgroundImageProps, BackgroundImageValue } from '../types';

export const useBackgroundImage = (props: BackgroundImageProps, emit: any) => {
  const fixedValue = {
    image: {
      src: 'https://ucarecdn.com/5e27d43d-eb1e-4e71-b7e1-4158f3071bb6/-/format/auto/',
      width: 1200,
      height: 480,
    },
    size: 'cover',
    position: {
      x: 50,
      y: 50,
    },
    repeat: 'no-repeat',
    attachment: 'scroll',
    preload: false,
  };

  const defaultValue = computed(() => {
    return props.compoDefaultValue || fixedValue;
  });

  const handleChange = (value?: BackgroundImageValue) => {
    emit('controlChange', value);
  };
  const handleOnChange = (value?: BackgroundImageValue) => {
    emit('controlOnChange', value);
  };
  const handleClear = () => {
    handleChange({
      ...props.value,
      image: {
        src: '',
      },
    });
  };

  const handleChangeValue = (key: keyof BackgroundImageValue, value?: any) => {
    const newValue = {
      ...props.value,
      [key]: value,
    };
    handleChange(newValue);
  };
  const handleSetDefaultValue = () => {
    if (!props.value?.image?.src) handleChange(defaultValue.value);
  };
  return {
    handleChange,
    handleOnChange,
    handleClear,
    handleChangeValue,
    handleSetDefaultValue,
  };
};

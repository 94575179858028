<script setup lang="ts">
import { GInput } from '@gem/uikit-v2';
import type { ControlInputTextTypes } from './type';
import { useInputText } from './hooks/useInputText';

const props = defineProps<ControlInputTextTypes>();
const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
}>();
const { handleControlChange, handleControlOnChange } = useInputText(props, emit);
</script>

<template>
  <GInput
    v-bind="props"
    @clear="handleControlChange('')"
    @click-out-side="handleControlChange"
    @change="handleControlChange"
    @on-change="handleControlOnChange" />
</template>

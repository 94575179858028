import type { InjectionKey } from 'vue';
import type { ElementSettingProps } from '@gem/element-setting-ui';
import type useGradientColor from '../components/color-picker/hooks/useGradientColor';

export const GRADIENT_PROVIDE_KEY: InjectionKey<
  Omit<
    ReturnType<typeof useGradientColor>,
    'selectedPoint' | 'setSelectedPointID' | 'pickRecentColor' | 'selectedPointPosition'
  >
> = Symbol('GRADIENT_CONTROL');

export const ELEMENT_SETTINGS_PROVIDE_KEY: InjectionKey<
  Pick<ElementSettingProps, 'controlChange' | 'controlOnChange'>
> = Symbol('CONTROL_CHANGE');

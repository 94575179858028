import type { InjectionKey } from 'vue';
import { inject } from 'vue';

export const strictInject = <T>(key: InjectionKey<T>, defaultValue?: T) => {
  const resolved = inject(key, defaultValue);
  if (!resolved) {
    throw new Error(`Could not resolve ${key}`);
  }

  return resolved;
};

import type { Maybe, OptionItem } from '@gem/common';
import { debounce, validateEmail } from '@gem/common';
import type { ComputedRef } from 'vue';
import { nextTick } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import type { List, PickLinkProps } from '../types';
import { createMailtoLink, parseMailto } from './parseMailto';
import { useDomainAutoComplete } from './useDomainAutoComplete';
import { useParseLinkToType } from './useParseLinkToType';

export const usePickLink = (props: PickLinkProps, emit: any) => {
  const defaultType = 'open-page';
  const searchVal = ref('');
  const refInputSearch = ref<HTMLInputElement>();
  const infinityScroll = ref<HTMLElement | null>(null);
  const typeLink = ref<string>(defaultType);
  const isValidEmail = ref(true);
  const val = computed(() => props.value);
  const isEnableMoreSetting = computed(() => typeLink.value === 'open-page' && val.value.link !== '');
  const dataSources = computed(() => props.dataSources);
  const currentTab = computed(() => props.currentTab ?? 'pages');
  const isInstant = computed(() => props.isInstant);
  const shopDomain = computed(() => props.shopDomain);
  const apiLoading = computed(() => props.apiLoading);
  const hiddenOptions = computed(() => props.hiddenOptions);
  const list = ref(dataSources.value[currentTab.value]);

  const newTabOptions = ref<OptionItem[]>([
    { label: 'Yes', value: '_blank' },
    { label: 'No', value: '_self' },
  ]);

  const followingOptions = ref<OptionItem[]>([
    { label: 'Yes', value: true as any },
    { label: 'No', value: false as any },
  ]);

  const baseTypeList: OptionItem[] = [
    { value: 'stay-on-page', label: 'Stay on page' },
    { value: 'open-page', label: 'Open page' },
    { value: 'scroll-to', label: 'Scroll to' },
    { value: 'open-popup', label: 'Open popup' },
    { value: 'send-email', label: 'Send email' },
    { value: 'call-phone', label: 'Make phone call' },
  ];

  const pickLinkTypeList = computed(() => {
    return baseTypeList.filter((el) => !hiddenOptions.value?.[el.value || '']);
  });

  const pageTypesOptions = computed(() => {
    return isInstant.value
      ? [{ label: 'Pages', value: 'pages' }]
      : [
          { label: 'Landing pages', value: 'pages' },
          { label: 'Collection pages', value: 'collections' },
          { label: 'Product pages', value: 'products' },
          { label: 'Blog posts', value: 'articles' },
        ];
  });

  const dataScrollTo: ComputedRef<List[]> = computed(() =>
    dataSources.value.scrollToList?.map((el: any, i: number) => ({
      title: el?.name ?? `Section ${i + 1}`,
      id: `#${el.id}`,
      handle: `#${el.id}`,
    })),
  );
  const listScrollTo = computed(() => [
    { id: '#scroll-to-top', title: 'Top', handle: '#scroll-to-top' },
    ...dataScrollTo.value,
  ]);

  const isTypeOpenPage = computed(() => typeLink.value === 'open-page');
  const isTypeScrollTo = computed(() => typeLink.value === 'scroll-to');

  const pageLinkList = computed(() => {
    if (isValidLink.value && suggestionLinks.value.length) return suggestionLinks.value;

    return isTypeScrollTo.value ? listScrollTo.value : list.value;
  });

  const settingTitle = computed(() => {
    return { en: `${isTypeScrollTo.value ? 'Section' : 'Page/link'}` };
  });

  const label = computed(() => {
    return { en: `${isTypeScrollTo.value ? 'Scroll To' : 'Open Page'}` };
  });

  const helpSettingTitle = computed(() => {
    return isTypeScrollTo.value ? undefined : { content: 'Choose page or link' };
  });

  const currentHandle = computed(() => {
    if (typeLink.value === 'open-page') {
      return val.value.link?.replace(`/${currentTab.value === 'articles' ? 'blogs' : currentTab.value}/`, '');
    }
    return val.value.link;
  });

  const activePageTypeOption = computed(() => {
    return isValidLink.value ? '' : currentTab.value;
  });

  const isActivePageItem = (handle: string) => {
    return handle === currentHandle.value;
  };

  const onOpenPageLink = (handle: string) => {
    const pageData = isInstant.value ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const basePathName = pageData[currentTab.value]?.pathName || '';
    const pageHandle = isValidLink.value ? handle : `${basePathName}/${handle}`;
    window.open(`https://${shopDomain.value}${pageHandle}`, '_blank');
  };

  const displayComboValue = computed(() => {
    const { isValid } = useDomainAutoComplete(val.value?.link ?? '');
    const title = list.value && list.value?.find((el: any) => el.handle === currentHandle.value)?.title;
    return typeLink.value === 'open-page' && !isValid ? title : currentHandle.value;
  });

  const changePageType = (val: any) => {
    if (isValidLink.value) return;
    emit('change-tab-with-search', val, searchVal.value);
  };

  const popupList: ComputedRef<any[]> = computed(() =>
    dataSources.value.popup.map((el: any) => ({ label: el.name, value: `#el-${el.id}` })),
  );

  const dataMailto = computed(() => parseMailto(val.value?.link || ''));

  const typeLinkToCurrentTab = (type: string) => {
    switch (type) {
      case 'open-page':
        return 'pages';
      case 'scroll-to':
        return 'listScrollTo';
      case 'open-popup':
        return 'popup';
      default:
        return 'pages';
    }
  };

  const checkTypeLink = () => {
    typeLink.value = val.value?.type || useParseLinkToType(val.value?.link ?? '');
    !val.value?.type && setTabSelected(typeLinkToCurrentTab(typeLink.value));
    if (typeLink.value === 'open-page') {
      if (val.value?.link?.startsWith('/products')) {
        setTabSelected('products');
      } else if (val.value?.link?.startsWith('/collections')) {
        setTabSelected('collections');
      } else if (val.value?.link?.startsWith('/blogs')) {
        setTabSelected('articles');
      }
    }
  };

  const isValidLink = ref(false);
  const suggestionLinks = ref<any[]>([]);

  const changeMoreSettings = (_id: string, value?: any) => {
    const data = { ...val.value, [_id]: value };
    emit('control-change', props.id, data);
  };

  const changeLink = (value: any) => {
    debounce(() => {
      emit('control-change', props.id, { ...val.value, link: value, type: typeLink.value });
    }, 0);
  };

  const SHOPIFY_PAGE_DATA: {
    [key: string]: { title: string; uri: string; pathName: string };
  } = {
    collections: {
      title: 'Collection',
      uri: `https://${shopDomain.value}/collections`,
      pathName: '/collections',
    },
    products: {
      title: 'Product',
      uri: `https://${shopDomain.value}/products`,
      pathName: '/products',
    },
    articles: {
      title: 'Articles',
      uri: `https://${shopDomain.value}/blogs`,
      pathName: '/blogs',
    },
    pages: {
      title: 'Page',
      uri: `https://${shopDomain.value}/pages`,
      pathName: '/pages',
    },
  };

  const INSTANT_PAGE_DATA: {
    [key: string]: { title: string; uri: string; pathName: string };
  } = {
    pages: {
      title: 'Page',
      uri: `https://${shopDomain.value}/`,
      pathName: '',
    },
  };

  const changePageUrl = (value: Maybe<string>) => {
    const pageData = isInstant.value ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const basePathName = pageData[currentTab.value]?.pathName || '';
    const handle = isValidLink.value ? value : `${basePathName}/${value}`;
    changeLink(handle);
  };

  const onOpenPopoverPages = () => {
    list.value = list.value.sort((a: any, b: any) => {
      if (a.handle === currentHandle.value) return -1;
      if (b.handle === currentHandle.value) return 1;
      return 0; // Keep the relative order of items if neither matches
    });
    onFocusSearchBox();
  };

  const handleChangeType = (_id: any, value: any) => {
    typeLink.value = value;
    setTabSelected(typeLinkToCurrentTab(value));
    changeLink('');
  };

  const onInputSearchBox = (value: string) => {
    debounce(() => {
      if (!value) return onClearSearchBox();
      searchVal.value = value;

      const { isValid, completedDomains } = useDomainAutoComplete(searchVal.value);
      isValidLink.value = isValid;
      if (isValidLink.value || completedDomains?.length > 0) {
        suggestionLinks.value = completedDomains;
      } else {
        onSearch(value);
      }
    }, 10);
  };

  const onEnterSearchBox = (close: () => void) => {
    if (typeLink.value === 'open-page') {
      validateSearchBox(close);
    }
  };

  const validateSearchBox = (close?: () => void) => {
    const inputVal = searchVal.value;
    if (!inputVal || !inputVal.trim() || inputVal === val.value?.link) return;

    const { isValid } = useDomainAutoComplete(inputVal);
    if (isValid) {
      !close && emit('control-change', props.id, { ...val.value, link: inputVal });
      close && close();
      searchVal.value = '';
    }
  };

  const onValidateEmail = (value: string) => {
    isValidEmail.value = validateEmail(value);
  };

  const onFocusSearchBox = () => {
    nextTick(() => {
      refInputSearch.value?.focus();
    });
  };

  const onClearSearchBox = () => {
    setTabSelected(currentTab.value);
    searchVal.value = '';
    infinityScrollToTop();
    onSearch('');
    onFocusSearchBox();
    isValidLink.value = false;
    list.value = dataSources.value[currentTab.value];
  };

  const infinityScrollToTop = () => {
    if (infinityScroll.value) {
      infinityScroll.value.scrollTop = 0;
    }
  };

  const changeEmailLink = (key: string, value: string) => {
    const data = { ...dataMailto.value, [key]: value };
    const link = createMailtoLink(data as any);
    changeLink(link);
  };

  const handleClearCombo = () => {
    changeLink('');
  };

  const setTabSelected = (tab: string) => {
    emit('set-tab-selected', tab);
  };

  const onSearch = (keyword: string) => {
    emit('control-search', keyword);
  };

  const showMore = () => {
    emit('show-more');
  };

  const createPopup = () => {
    emit('create-popup');
  };

  onMounted(() => {
    list.value = dataSources.value[currentTab.value];
    checkTypeLink();
  });

  watch(val, () => {
    checkTypeLink();
  });

  watch(currentTab, () => {
    list.value = dataSources.value[currentTab.value];
  });

  watch(dataSources, () => {
    list.value = dataSources.value[currentTab.value];
  });

  return {
    val,
    comboDisplay: computed(() => {
      return {
        title: settingTitle.value,
        label: label.value,
        helpTitle: helpSettingTitle.value,
        display: displayComboValue.value,
        open: onOpenPopoverPages,
      };
    }),
    newTabOptions,
    followingOptions,
    pickLinkTypeList,
    pageTypesOptions,
    activePageTypeOption,
    isEnableMoreSetting,
    isTypeOpenPage,
    searchVal,
    refInputSearch,
    infinityScroll,
    isValidLink,
    isValidEmail,
    pageLinkList,
    popupList,
    dataMailto,
    currentTab,
    typeLink,
    apiLoading,
    setTabSelected,
    showMore,
    onSearch,
    createPopup,
    changeMoreSettings,
    handleChangeType,
    changePageUrl,
    changeLink,
    onEnterSearchBox,
    onInputSearchBox,
    onValidateEmail,
    onFocusSearchBox,
    changePageType,
    handleClearCombo,
    onClearSearchBox,
    changeEmailLink,
    isActivePageItem,
    onOpenPageLink,
  };
};

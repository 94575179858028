import type {
  ActionSettingInput,
  DisplayType,
  GridSettingInput,
  LayoutValue,
  RowLayoutProps,
} from '@gem/element-setting-ui';
import { computed } from 'vue';
import type { ScreenType } from '@gem/common';
import { getResponsiveValueByScreen } from '@gem/common';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';

export const useRowLayoutSettingsUpdate = (props: RowLayoutProps, emit?: any) => {
  const sideBarStore = useSettingSideBarStore();

  const items = computed(() => {
    const currentScreen = props.isSupportResponsiveOrder ? props.currentScreen : 'desktop';
    return [...(props.childrens ?? [])].sort((a, b) => {
      const order1 = getResponsiveValueByScreen(a.styles?.order, currentScreen);
      const order2 = getResponsiveValueByScreen(b.styles?.order, currentScreen);
      if (order1 !== order2) {
        return Number(order1) - Number(order2);
      }
      return 0;
    });
  });

  const isShowGridArrange = computed(() => (props.value?.cols?.length || 0) > 1);
  const isShowGapSetting = computed(() => props.childrens && props.childrens?.length > 1);

  const orderItems = computed(() => {
    return items.value.map((v) => ({
      id: v.uid,
      title: 'Column',
    }));
  });

  const maxColumn = computed(() => {
    return props?.currentScreen === 'desktop' ? props.maxCol : props.childrens?.length ?? 6;
  });

  const displayValue = computed(() => {
    return props.value?.display ?? 'fill';
  });

  const getReOrderValue = (data: { id?: string; title?: string }[], device?: ScreenType) => {
    const input: ActionSettingInput[] = (data.filter((i) => !!i.id) as { id: string; title?: string }[]).map(
      (item, index) => {
        return {
          groupType: 'style',
          componentUid: item.id,
          controlType: 'input',
          controlId: 'order',
          newValue: index,
          screenId: device || props.currentScreen || 'desktop',
          hasDevices: true,
        };
      },
    );
    return input;
  };

  const handleReOrder = (input: ActionSettingInput[]) => {
    emit('control-change-children', input);
  };

  const handleChangeDisplay = (type: DisplayType, screenId: ScreenType) => {
    emit(
      'control-change',
      props.id,
      {
        ...getValueByDevice(screenId),
        display: type,
      },
      screenId,
    );
    changeActiveDevice(screenId);
  };

  const handleUpdateCols = (type: 'change' | 'onChange', cols: number[] | undefined, screenId: ScreenType) => {
    const newValue = {
      ...props.value,
      cols,
    };
    if (type === 'change') emit('control-change', props.id, newValue, screenId);
    else emit('control-on-change', props.id, newValue, screenId);
    changeActiveDevice(screenId);
  };

  const handleChangeLayout = (newCols: number, device: ScreenType) => {
    if (!newCols) return;
    if (device === 'desktop') {
      const input: GridSettingInput[] = [
        {
          newValue: {
            ...props.value,
            cols: Array.from({ length: newCols }, () => 12 / newCols),
          },
          screenId: 'desktop',
        },
        {
          newValue: undefined,
          screenId: 'tablet',
        },
        {
          newValue: {
            cols: [12],
            display: 'fill',
          },
          screenId: 'mobile',
        },
      ];
      emit('control-change-layout', input);
    } else {
      emit(
        'control-change',
        props.id,
        {
          ...props.value,
          cols: Array.from({ length: newCols }, () => 12 / newCols),
        },
        device,
      );
    }
    changeActiveDevice(device);
  };

  const handleChangeGap = (type: 'onChange' | 'change', value: string | undefined, screenId: ScreenType) => {
    const newData: LayoutValue = {
      ...props.value,
      gap: value,
    };
    if (type === 'change') emit('control-change', props.id, newData, screenId);
    else emit('control-on-change', props.id, newData, screenId);
    changeActiveDevice(screenId);
  };

  const handleRemoveItem = (uid: string) => {
    emit('remove-item', uid);
  };

  const handleCopyItem = (uid: string) => {
    emit('copy-item', uid);
  };

  const changeActiveDevice = (device: ScreenType) => {
    sideBarStore.setCurrentDevice(device);
  };

  const getValueByDevice = (device?: ScreenType) => {
    return getResponsiveValueByScreen(props.devices, device)?.default;
  };

  return {
    maxColumn,
    displayValue,
    orderItems,
    isShowGapSetting,
    isShowGridArrange,
    handleChangeLayout,
    handleReOrder,
    handleChangeDisplay,
    handleUpdateCols,
    handleCopyItem,
    handleRemoveItem,
    handleChangeGap,
    changeActiveDevice,
    getValueByDevice,
    getReOrderValue,
  };
};

import type { ColorListProps } from '../types';
import { computed } from 'vue';
import { getUniqueColors } from '../helpers';

export const useColorList = (props: ColorListProps, emits: any) => {
  const onSelected = (color: string) => {
    emits('onPickColor', color);
  };
  const isActive = (myColor: string) => {
    return myColor === props.color;
  };

  const allColors = computed(() => {
    const colorList = [...props.colors, ...(props.systemColor ?? [])];
    return getUniqueColors(colorList);
  });

  return {
    allColors,
    isActive,
    onSelected,
  };
};

<script setup lang="ts">
import { provide } from 'vue';
import type { ColorPickerProps } from '../types';
import { type ColorSuggestions } from '@gem/common';
import InputColor from './InputColor.vue';
import ColorList from './ColorList.vue';
import ColorOnly from './ColorOnly.vue';
import GradientPicker from './gradient-picker/GradientPicker.vue';
import { GRADIENT_PROVIDE_KEY } from '../../../consts/provideKeys';
import { useGradientColorPicker } from '../hooks/useGradientColorPicker';

const props = defineProps<ColorPickerProps>();

const emits = defineEmits<{
  (e: 'controlChange', controlId: string, value?: string): void;
  (e: 'controlOnChange', controlId: string, value?: string): void;
  (e: 'saveMyColors', value?: ColorSuggestions): void;
}>();

const {
  gradientColorObject,
  selectedPointID,
  selectedPoint,
  showTransparentColor,
  shouldReRenderDueToInputChange,
  recentGradientColors,
  handleInputChange,
  handleInputOnChange,
  updateColor,
  removePoint,
  pickRecentColor,
  addPoint,
  updateAngle,
  updatePosition,
  selectPoint,
} = useGradientColorPicker(props, emits);

provide(GRADIENT_PROVIDE_KEY, {
  selectedPointID,
  addPoint,
  updateAngle,
  updateColor,
  updatePosition,
  removePoint,
  selectPoint,
});
</script>

<template>
  <div class="flex flex-col gap-16">
    <GradientPicker :gradient-color="gradientColorObject" @on-remove-point="removePoint" />
    <InputColor
      :value="selectedPoint?.color.getColor()"
      :show-transparent-color="showTransparentColor"
      @on-change="handleInputOnChange"
      @change="handleInputChange" />
    <ColorOnly
      :key="`${selectedPointID}_${shouldReRenderDueToInputChange.toString()}`"
      :value="selectedPoint?.color.getColor()"
      :is-auto-update-value="false"
      @change-color="(value) => updateColor(value)" />
    <ColorList
      v-if="recentGradientColors && recentGradientColors.length > 0"
      title="Recent colors"
      :colors="recentGradientColors"
      :color="value"
      @on-pick-color="pickRecentColor" />
  </div>
</template>

import type { StyleValue } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import { Color } from '../Color';
import type { WhereInput } from '@gem/common';
import { isValidHexWithoutPrefix, isValidColorFormat, getHexColorThreeDigit, lightenDarkenColor } from '@gem/common';
import type { ColorOnlyProps } from '../types';

declare global {
  interface Window {
    EyeDropper: any;
  }
}
interface EyeDropperConstructor {
  new (): EyeDropperInterface;
}

interface ColorSelectionOptions {
  signal: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropperInterface extends EyeDropperConstructor {
  open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
}

export const useColorOnly = (props: ColorOnlyProps, emit: any) => {
  const color = ref(Color.fromString(props.value));
  const hueArea = ref<HTMLDivElement>();
  const huePicker = ref<HTMLDivElement>();
  const surface = ref<HTMLDivElement>();
  const picker = ref<HTMLDivElement>();
  const alphaArea = ref<HTMLDivElement>();
  const alphaPicker = ref<HTMLDivElement>();
  const iframeDocument = ref<HTMLIFrameElement>();
  let debounceChangeValue: ReturnType<typeof setTimeout>;

  const limitDim = (pos: number, dimension: number) => {
    return Math.max(-4, Math.min(pos - 4, dimension - 12));
  };

  const alphaPickerStyle = computed<StyleValue>(() => {
    const rect = alphaArea.value?.getBoundingClientRect();
    if (rect) {
      const left = Math.round(rect.width * color.value.a);
      return {
        left: `${limitDim(left, rect.width)}px`,
        background: `hsl(${color.value.hue}, 100%, 50%)`,
      };
    }
    return {};
  });

  const pickerStyle = computed<StyleValue>(() => {
    const rect = surface.value?.getBoundingClientRect();
    let value = color.value.value;
    if (color.value.format === 'HSV') value = color.value.value;
    if (color.value.format === 'HSL') value = color.value.lightness;
    if (rect) {
      const width = rect.width;
      const height = rect.height;
      const x = ((color.value.saturation * width) / 100) | 0;
      const y = (height - (value * height) / 100) | 0;
      return {
        transform: `translate(${limitDim(x, width)}px, ${limitDim(y, height)}px)`,
        background: `rgb(${color.value.r}, ${color.value.g}, ${color.value.b})`,
      };
    }
    return {};
  });

  const hueStyle = computed<StyleValue>(() => {
    const rect = hueArea.value?.getBoundingClientRect();
    const left = ((color.value.hue * (rect?.width ?? 0)) / 360) | 0;
    return {
      left: `${limitDim(left, rect?.width ?? 248)}px`,
      background: `hsl(${color.value.hue}, 100%, 50%)`,
    };
  });

  const alphaStyle = computed<StyleValue>(() => {
    return {
      background: `linear-gradient(to left, ${color.value.formatRGBA(1)} 0%, ${color.value.formatRGBA(0)} 100%)`,
    };
  });

  const surfaceStyle = computed<StyleValue>(() => {
    return { backgroundColor: `hsl(${color.value.hue}, 100%, 50%)` };
  });

  const noHashtagHexColor = ref(color.value?.getHexColor()?.replace('#', ''));

  const borderColor = computed(() => lightenDarkenColor(props.value as string));

  function changeColor(whereInput?: WhereInput) {
    clearTimeout(debounceChangeValue);
    debounceChangeValue = setTimeout(
      () => {
        emit('change-color', color.value.getColor(), whereInput);
      },
      whereInput === 'hexInput' ? 200 : whereInput === 'opacityInput' ? 500 : 200,
    );
  }

  function changeColorOnThreeDigitHex(value: string) {
    noHashtagHexColor.value = value;
    color.value = Color.fromString(value);
    changeColor('hexInput');
  }

  function onChangeHexValue(e: Event) {
    clearTimeout(debounceChangeValue);
    const value = (e.target as HTMLInputElement).value;
    if (isValidHexWithoutPrefix(value)) {
      color.value = Color.fromString('#' + value);
      changeColor('hexInput');
      return;
    }
    if (getHexColorThreeDigit(value)) {
      changeColorOnThreeDigitHex(value);
      return;
    }
    if (isValidColorFormat(value)) {
      color.value = Color.fromString(value);
      changeColor('hexInput');
    }
  }

  function onBlurHexInput(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    const validValue = getHexColorThreeDigit(value);
    if (validValue) {
      color.value = Color.fromString(validValue);
      changeColor('hexInput');
    }
  }

  function updateColor(e: MouseEvent) {
    if (surface.value && picker.value) {
      const rect = surface.value.getBoundingClientRect();
      let x = e.pageX - rect.x;
      let y = e.pageY - rect.y;

      const width = rect.width;
      const height = rect.height;

      if (x > width) x = width;
      if (y > height) y = height;
      if (x < 0) x = 0;
      if (y < 0) y = 0;

      const value = (100 - (y * 100) / height) | 0;
      const saturation = ((x * 100) / width) | 0;

      if (color.value.format === 'HSV') color.value.setHSV(color.value.hue, saturation, value);
      if (color.value.format === 'HSL') color.value.setHSL(color.value.hue, saturation, value);

      changeColor();
    }
  }

  function updateHueSlider(e: MouseEvent) {
    if (hueArea.value && huePicker.value) {
      const hueRect = hueArea.value.getBoundingClientRect();
      let x = e.pageX - hueRect.x;
      const width = hueRect.width;

      if (x < 0) x = 0;
      if (x > width) x = width;

      // TODO 360 => 359
      let hue = Math.floor((359 * x) / width);

      if (hue === 360) hue = 359;
      color.value.setHue(hue);

      changeColor('hexInput');
    }
  }

  function updateAlphaSlider(e: MouseEvent) {
    if (alphaArea.value && alphaPicker.value) {
      const alphaRect = alphaArea.value.getBoundingClientRect();
      let x = e.pageX - alphaRect.x;
      const width = alphaRect.width;

      if (x < 0) x = 0;
      if (x > width) x = width;
      color.value.a = parseFloat((x / width).toFixed(2));

      changeColor();
    }
  }

  function setMouseTracking(elem: HTMLElement, callback: (e: MouseEvent) => void) {
    document.addEventListener('mouseup', function () {
      document.removeEventListener('mousemove', callback);
    });
    iframeDocument.value?.addEventListener('mouseup', function () {
      document.removeEventListener('mousemove', callback);
    });
    elem.addEventListener('mousedown', function (e) {
      e.preventDefault();
      callback(e);
      document.addEventListener('mousemove', callback);
    });
  }

  function getIframeDocument() {
    const iframe = document.querySelector('iframe');
    if (!iframe) {
      return;
    }
    iframeDocument.value = iframe.contentDocument || (iframe.contentWindow as any).document;
  }

  const stopPropagation = (e: Event) => {
    e.stopPropagation();
  };

  const disableShortcutDelete = (e: KeyboardEvent) => {
    if (e.key === 'Delete') {
      e.stopPropagation();
    }
  };

  onMounted(() => {
    getIframeDocument();
    if (surface.value) {
      setMouseTracking(surface.value, updateColor);
    }

    if (hueArea.value) {
      setMouseTracking(hueArea.value, updateHueSlider);
    }
    if (alphaArea.value) {
      setMouseTracking(alphaArea.value, updateAlphaSlider);
    }
  });

  watch(
    () => props.value,
    (newV) => {
      if (!props.isAutoUpdateValue) {
        return;
      }
      setTimeout(() => {
        color.value = Color.fromString(newV);
      }, 0);
    },
  );

  return {
    picker,
    surface,
    huePicker,
    alphaPicker,
    hueArea,
    alphaArea,
    alphaPickerStyle,
    pickerStyle,
    hueStyle,
    alphaStyle,
    surfaceStyle,
    borderColor,
    noHashtagHexColor,
    onChangeHexValue,
    onBlurHexInput,
    stopPropagation,
    disableShortcutDelete,
  };
};

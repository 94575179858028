<script setup lang="ts">
import type { SettingUIHelpType } from '@gem/element-setting-ui';
import { GIcon } from '@gem/icons';
import { GButton, GPopover } from '@gem/uikit-v2';

withDefaults(defineProps<SettingUIHelpType>(), {});

const btnClick = (link: string) => {
  window.open(link, '_blank');
};
</script>
<template>
  <GPopover
    :no-padding="true"
    :placement-offset="8"
    closeable
    trigger="hover"
    :ignore-outside-class="['button-tip']"
    cls="bg-dark-400"
    button-class="!w-full"
    wrap-content-class="!w-full"
    placement="top">
    <div class="button-tip flex cursor-pointer items-center">
      <GIcon name="polaris-question-circle" :size="20" />
    </div>
    <template #content>
      <div class="flex w-[232px] flex-col gap-8 p-8">
        <template v-if="media">
          <template v-if="media.type === 'image'">
            <img class="w-full" :src="media.value" alt="" />
          </template>
          <template v-if="media.type === 'youtubeVideoID'">
            <iframe
              title="Video"
              :src="`https://www.youtube.com/embed/${media.value}`"
              width="100%"
              height="auto"
              allowfullscreen></iframe>
          </template>
        </template>
        <p class="text-12 text-light-450 font-normal">{{ content }}</p>
        <GButton
          v-if="button?.link && button?.label"
          type="tertiary"
          size="semi-medium"
          button-width="full"
          @click="btnClick(button.link)"
          >{{ button.label }}</GButton
        >
      </div>
    </template>
  </GPopover>
</template>
